
import {
  Button,
  FormControl,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";
import React, { useCallback, useState } from "react";
import { Routes, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "../../../../hooks/use-form.hook";
import { useNotifications } from "../../../../hooks/use-notifications";
import { buttonStyles } from "../../../../styles/button.styles";
import OneButtonDialog from "../../../../views/dialog/one-button-dialog";
import { useProductCreate } from "../../hooks/use-product-create";
import {
  ProductEndpointUpdate,
  ProductHeaderUpdate,
  ProductHostUpdate,
  ProductUpdate,
} from "../../model/product-update";
import { productCreateSchema } from "../../model/product-validations";
import { ProductEditDetails } from "../product-edit/product-edit-details";
import { ProductEditEndpoints } from "../product-edit/product-edit-endpoints";
import { ProductEditHeaders } from "../product-edit/product-edit-headers";
import { ProductEditHosts } from "../product-edit/product-edit-hosts";
import { ProductReview } from "../product-view/product-review";
import { serviceWizardStyles } from "./product-wizard.styles";
import { useFormsStyles } from "../../../../styles/forms.styles";
import { Helmet } from 'react-helmet';
import { TypographyHighLight } from '../../../../components/app-header-highlight/typographyHighlight';
import Select from '@visa/vds/select';
import { vdsStyleOverrides } from '../../../../vds-overrides.styles';
import { FormControlLabel, Switch, Grid,useTheme, useMediaQuery, NativeSelect, ToggleButtonGroup, ToggleButton } from '@mui/material';
import ButtonText from '@visa/vds/button-text';


enum Steps {
  Details = 0,
  Hosts = 1,
  Endpoints = 2,
  Headers = 3,
  Review = 4,
}

const steps: string[] = ['Product Details', 'Host URL', 'Endpoints', 'Allowed Headers', 'Review'];

const defaultProduct: ProductUpdate = {
  id: 0,
  name: '',
  documentationUrl: '',
  statusType: '',
  description: '',
  contextRoot: '',
  productEndpoints: [],
  productHosts: [],
  productHeaders: [],
  productOwners: [],
  productId: '',
  requestId: '',
  productB2C2BArgs: undefined,
};

const b2c2bArgsBusiness = [
  {
    business: "VDP",
  },
];

export const ProductWizard: React.FC = () => {
  const styles = serviceWizardStyles();
  const vdsStyles = vdsStyleOverrides();
  const formStyles = useFormsStyles();
  const btnStyles = buttonStyles();
  const navigate = useNavigate();
  const { pushNotification } = useNotifications();
  const { state }: any = useLocation();
  const [blockNavigation, setBlockNavigation] = useState(true);
  const [activeStep, setActiveStep] = useState<Steps>(Steps.Details);
  const initialProduct: ProductUpdate = !!state && !!state.wizardObject ? state.wizardObject : defaultProduct;
  const { createProductAsync } = useProductCreate();
  const { inputs, errors, handleInputChange, handleInputValueChange, handleValidate } = useForm(initialProduct, productCreateSchema);
  const [btnNavigateStr, setBtnNavigateStr] = React.useState(`/b2c/products`);

  const handleSubmit = useCallback(async () => {
    setBlockNavigation(false);
    const success = await createProductAsync(inputs);
    if (success && success.status) {
      setBtnNavigateStr(`/b2c/products/${success?.productId}`)
      handleOpenDialog();
    } else {
      setBlockNavigation(true);
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
  }, [inputs, createProductAsync]);

  const handleCancel = useCallback(() => {
    navigate('/b2c/products');
  }, [navigate]);

  const isFinalStep = useCallback((): boolean => {
    return (activeStep === steps.length - 1);
  }, [activeStep]);

  const areDetailsInvalid = useCallback(
    (errors: Record<string, any>) => errors.name || errors.contextRoot || errors.documentationUrl || errors.productOwners,
    []
  );

  const handleValidateStep = useCallback(async () => {
    const [, errors] = await handleValidate();
    if (activeStep === Steps.Endpoints && inputs.contextRoot) {
      let validExternalPath = true;
      let contextRoot = inputs?.contextRoot || "";

      if (inputs?.productEndpoints) {
        validExternalPath = inputs?.productEndpoints?.every((endpoint) => {
          let pathStrings = endpoint?.externalPath?.split("/");
          return pathStrings.indexOf(contextRoot) === 2;
        });
      }
      if (!validExternalPath) {
        pushNotification({
          message: `Context root should be present in the external path`,
          type: "snackbar",
          variant: "error",
        });
        return false;
      }
    }
    if (activeStep === Steps.Endpoints) {
      let validB2CMleType = true;
      let validB2BMleType = true;
      if (inputs?.productB2C2BArgs?.useVp2Connector && inputs?.productEndpoints) {
        validB2CMleType = inputs?.productEndpoints?.every((endpoint) => {
          return endpoint?.b2cMleType !== undefined;
        });
      }
      if (!validB2CMleType) {
        pushNotification({
          message: `B2C Mle Type is required when using VP2 Connector`,
          type: "snackbar",
          variant: "error",
        });
        return false;
      }
      if (inputs?.productB2C2BArgs?.useVp2Connector && inputs?.productEndpoints) {
        validB2BMleType = inputs?.productEndpoints?.every((endpoint) => {
          return endpoint?.b2bMleType !== undefined;
        });
      }
      if (!validB2BMleType) {
        pushNotification({
          message: `B2B Mle Type is required when using VP2 Connector`,
          type: "snackbar",
          variant: "error",
        });
        return false;
      }
    }
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    if (activeStep === Steps.Hosts) {
      
      if (inputs?.productHosts.length === 0) {
        pushNotification({
          message: `At least one Host Url is required.`,
          type: "snackbar",
          variant: "error",
        });
        return false;
      }
    }

    if (activeStep === Steps.Endpoints) {
      
      if (inputs?.productEndpoints.length === 0) {
        pushNotification({
          message: `At least one Endpoint is required.`,
          type: "snackbar",
          variant: "error",
        });
        return false;
      }
    }
    
    if (
      (activeStep === Steps.Details && areDetailsInvalid(errors)) ||
      (activeStep === Steps.Hosts && errors.productHosts && errors.business) ||
      (activeStep === Steps.Endpoints && errors.productEndpoints) ||
      (activeStep === Steps.Headers && errors.productHeaders)
    ) {
      if(errors["name"]){
        document.getElementById("product-name-id")?.focus();
      }else if(errors["contextRoot"]){
        document.getElementById("context-root-id")?.focus();
      }
      pushNotification({
        message: "Fix errors before continue",
        type: "snackbar",
        variant: "error",
      });
    } else {
      setActiveStep((activeStep) => activeStep + 1);
    }
  }, [activeStep, handleValidate, pushNotification, areDetailsInvalid, inputs]);

  const handleBack = useCallback(() => setActiveStep(activeStep => activeStep - 1), []);

  const handleBackOperations = useCallback(() => setActiveStep(Steps.Endpoints), []);
  const handleBackDetails = useCallback(() => setActiveStep(Steps.Details), []);
  const handleBackHosts = useCallback(() => setActiveStep(Steps.Hosts), []);
  const handleBackHeaders = useCallback(() => setActiveStep(Steps.Headers), []);

  const [selectValue, setSelectValue] = useState("false");

  const handleVp2ConnectorChange = useCallback(
    (evt: React.ChangeEvent<any>) => {
      handleInputChange(evt);
      setSelectValue(evt.target.value);
      if (evt.target.value === "false") {
        handleInputValueChange("productB2C2BArgs", {
          useVp2Connector: evt.target.value === "true",
          business: undefined,
        });
        handleInputValueChange("productHosts", []);
        let productEndpointsWithoutMleTypes = inputs.productEndpoints.map((
          { b2bMleType, b2cMleType, ...rest }) => rest);
        handleInputValueChange("productEndpoints", productEndpointsWithoutMleTypes);
      } else if (evt.target.value === "true") {
        handleInputValueChange("productB2C2BArgs", {
          useVp2Connector: evt.target.value === "true",
          business: "VDP",
        });
        const host = window.location.host;
        const splits = host.split("-");
        let hostUrls = [{hostType: "OCE", b2c2bConnectorUrl: ""}];
        if (splits.length > 2) {
          if (splits[2] === "devb.oce") {
            hostUrls[0].b2c2bConnectorUrl =
              "https://b2c2b-connector-service-devb.oce-np-sm-ddp-b-en.trusted.visa.com";
          } else if (splits[2] === "devwkspceb.oce") {
            hostUrls[0].b2c2bConnectorUrl =
              "https://b2c2b-connector-service-devwkspceb.oce-np-sm-ddp-b-en.trusted.visa.com";
          } else if (splits[2] === "qapenb.oce") {
            hostUrls[0].b2c2bConnectorUrl =
              "https://b2c2b-connector-service-qapenb.oce-np-sm-ddp-b-en.trusted.visa.com";
          } else if (splits[2] === "qaperfb.oce") {
            hostUrls[0].b2c2bConnectorUrl =
              "https://b2c2b-connector-service-qaperfb.oce-np-sm-ddp-b-en.trusted.visa.com";
          } else if (splits[2] === "qab.oce") {
            hostUrls[0].b2c2bConnectorUrl =
              "https://b2c2b-connector-service-qab.oce-np-sm-ddp-b-en.trusted.visa.com";
          } else if (splits[2] === "qaintb.oce") {
            hostUrls[0].b2c2bConnectorUrl =
              "https://b2c2b-connector-service-qaintb.oce-np-sm-ddp-b-en.trusted.visa.com";
          } else if (splits[2] === "aacertdc1b") {
            hostUrls[0].b2c2bConnectorUrl =
              "https://b2c2b-connector-service-aacertdc1b.oce-np-sm-ddp-b-en.trusted.visa.com";
          } else if (splits[2] === "aacertdc2b") {
            hostUrls[0].b2c2bConnectorUrl =
              "https://b2c2b-connector-service-aacertdc2b.oce-np-sm-ddp-b-en.trusted.visa.com";
          } else if (splits[2].includes("sbx")) {
              hostUrls[0].b2c2bConnectorUrl =
                "https://b2c2b-connector-service-sbxb-k8l73.oce-ex-ccte-sm-ddp-b.trusted.visa.com";
              hostUrls.push({hostType: "OCC", b2c2bConnectorUrl: "https://b2c2b-connector-service-sbxb-k8l55.occ-ex-ccte-sm-ddp-b.trusted.visa.com"});
          } else if (splits[2].includes("prod")) {
              hostUrls[0].b2c2bConnectorUrl =
                "https://b2c2b-connector-service-prodb2cb-k8l73.oce-ex-b2c-sm-ddp-b.trusted.visa.com";
              hostUrls.push({hostType: "OCC", b2c2bConnectorUrl: "https://b2c2b-connector-service-prodb2cb-k8l55.occ-ex-b2c-sm-ddp-b.trusted.visa.com"});
          }
          else {
            // for local testing
            hostUrls[0].b2c2bConnectorUrl =
              "https://b2c2b-connector-service-devb.oce-np-sm-ddp-b-en.trusted.visa.com";
          }
          handleInputValueChange("productHosts", [
            {
              url: hostUrls[0].b2c2bConnectorUrl,
              dataCenter: "OCE",
              id: -1,
            },
            ...(hostUrls[1] ? [{
              url: hostUrls[1]?.b2c2bConnectorUrl,
              dataCenter: "OCC",
              id: -1,
            }] : [])
          ]);
        } else if (splits.length === 1) {
          if (splits[0] === "innovationportal.pilot.visa.com") {
            hostUrls[0].b2c2bConnectorUrl =
              "https://b2c2b-connector-service-prodb2cb-k8l73.oce-ex-b2c-sm-ddp-b.trusted.visa.com";
            hostUrls.push({hostType: "OCC", b2c2bConnectorUrl: "https://b2c2b-connector-service-prodb2cb-k8l55.occ-ex-b2c-sm-ddp-b.trusted.visa.com"});  
          } else if (splits[0] === "sandbox.innovationportal.pilot.visa.com") {
            hostUrls[0].b2c2bConnectorUrl =
              "https://b2c2b-connector-service-sbxb-k8l73.oce-ex-ccte-sm-ddp-b.trusted.visa.com";
            hostUrls.push({hostType: "OCC", b2c2bConnectorUrl: "https://b2c2b-connector-service-sbxb-k8l55.occ-ex-ccte-sm-ddp-b.trusted.visa.com"});
          }
          handleInputValueChange("productHosts", [
            {
              url: hostUrls[0].b2c2bConnectorUrl,
              dataCenter: hostUrls[0].hostType,
              id: 1,
            },
            {
              url: hostUrls[1].b2c2bConnectorUrl,
              dataCenter: hostUrls[1].hostType,
              id: 1,
            },
          ]);
        }
      }
    },
    [handleInputChange, handleInputValueChange]
  );

  const handleB2C2BArgsBusinessChange = useCallback(
    (evt: React.ChangeEvent<any>) => {
      handleInputValueChange("productB2C2BArgs", {
        useVp2Connector: selectValue,
        business: evt.target.value,
      });
    },
    [handleInputChange, handleInputValueChange]
  );

  const handleHostsChange = useCallback(
    (hosts: ProductHostUpdate[]) => {
      handleInputValueChange("productHosts", hosts);
      if (inputs.productB2C2BArgs === undefined) {
        handleInputValueChange("productB2C2BArgs", {
          useVp2Connector: false,
          business: undefined,
        });
      }
    },
    [handleInputValueChange]
  );
  const handleEndpointsChange = useCallback(
    (endpoints: ProductEndpointUpdate[]) => { 
      return handleInputValueChange("productEndpoints", endpoints);
    },
    [handleInputValueChange]
  );
  const handleHeadersChange = useCallback(
    (productHeaders: ProductHeaderUpdate[]) =>
      handleInputValueChange("productHeaders", productHeaders),
    [handleInputValueChange]
  );
  const getActiveStepContent = useCallback((): any => {
    switch (activeStep) {
      case Steps.Details:
        return (
          <ProductEditDetails
            details={inputs}
            onInputChange={handleInputChange}
            onInputValueChange={handleInputValueChange}
            errors={errors}
            isCreate
          />
        );
      case Steps.Hosts:
        return (
          <>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Helmet>
                  <title>
                      Host URL | Add new product (2 of 5) | Visa Prototype Validation Platform
                  </title>
              </Helmet>
              <TypographyHighLight headerTitle="Host URL | Add new product (2 of 5)" variant='h5' />
              <Typography color="primary" className={styles.instruction1}>
                Please fill in your product B2C2B Args
              </Typography>
              <Grid container spacing={2} className={styles.marginB30}>
                <Grid item xs={6}>
                  <Typography className={formStyles.label}>
                    Use VP2 Connector*
                  </Typography>
                  <div>
                    <ToggleButtonGroup
                      size="large"
                      color="primary"
                      value={selectValue === "true"}
                      exclusive
                      onChange={handleVp2ConnectorChange}
                    >
                      <ToggleButton id="YES" value={true as any}>
                        YES
                      </ToggleButton>
                      <ToggleButton id="NO" value={false as any}>
                        NO
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  {selectValue !== undefined && selectValue === "true" ? (
                    <Select
                    aria-required
                    name="business"
                    label="Business*"
                    value={inputs.productB2C2BArgs?.business}
                    onChange={handleB2C2BArgsBusinessChange}
                    // showErrorText={!!errors.deploymentRegion}
                    // errorText={errors.deploymentRegion}
                    // invalid={!!errors.deploymentRegion}
                    className={vdsStyles.selectFullWidth}

                >
                    {b2c2bArgsBusiness.map(item => (
                        <option key={item.business} value={item.business}>{item.business}</option>
                    ))}
                </Select>


                    // <FormControl
                    //   className={clsx(formStyles.formControl80)}
                    //   required
                    // >
                    //   <Typography className={formStyles.label}>
                    //     Business*
                    //   </Typography>

                    //   <Select
                    //     labelId="business"
                    //     id="business"
                    //     name="business"
                    //     value={inputs.productB2C2BArgs?.business}
                    //     onChange={handleB2C2BArgsBusinessChange}
                    //     variant="outlined"
                    //     required
                    //   >
                    //     {b2c2bArgsBusiness.map((item) => (
                    //       <MenuItem key={item.business} value={item.business}>
                    //         {item.business}
                    //       </MenuItem>
                    //     ))}
                    //   </Select>
                    // </FormControl>
                  ) : (
                    <></>
                  )}
                  <br />
                </Grid>
              </Grid>
            </Grid>
            <ProductEditHosts
              productHosts={inputs.productHosts}
              useVp2Connector={selectValue === "true"}
              onChange={handleHostsChange}
              isCreate
            />
          </>
        );
      case Steps.Endpoints:
        return (
          <ProductEditEndpoints
            endpoints={inputs.productEndpoints}
            useVp2Connector={selectValue === "true"}
            onChange={handleEndpointsChange}
            isCreate
          />
        );
      case Steps.Headers:
        return (
          <ProductEditHeaders
            headers={inputs.productHeaders || []}
            onChange={handleHeadersChange}
            isCreate
          />
        );
      case Steps.Review:
        return (
          <ProductReview
            product={inputs}
            isEdit={true}
            editDetailsNav={handleBackDetails}
            editHeadersNav={handleBackHeaders}
            editHostsNav={handleBackHosts}
            editOperationsNav={handleBackOperations}
          />
        );
      default:
        setActiveStep(0);
        return "";
    }
  }, [
    activeStep,
    inputs,
    errors,
    handleInputChange,
    handleInputValueChange,
    handleVp2ConnectorChange,
    handleB2C2BArgsBusinessChange,
    handleHostsChange,
    handleEndpointsChange,
    handleHeadersChange,
    handleBackDetails,
    handleBackHeaders,
    handleBackHosts,
    handleBackOperations,
  ]);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const handleOpenDialog = () => {
    setDialogOpen(true)
  };
  const theme = useTheme();
  const mediaCheck = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <>
      <Routes>
        when={blockNavigation}
        message={'You may have pending changes that you will lose if you continue. Do you want to continue?'}
      </Routes>
      <Grid
        container
        justifyContent="center"
        alignItems="center">
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}  role="navigation" aria-label='Add new B2C product'>
          <Stepper activeStep={activeStep} alternativeLabel={!mediaCheck} orientation={mediaCheck ? 'vertical' : 'horizontal'}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Divider className={styles.divider} />
	</Grid>
        </Grid>
          <Grid container component={"main"} justifyContent='center'>
              {getActiveStepContent()}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={11}>
            <Grid container justifyContent="space-between" direction='row' wrap='nowrap'>
                <Grid container direction='row' columnGap={2} lg={4}>
                  <Grid item lg={4}>
                    <ButtonText isFullWidth onClick={handleCancel}>
                      Cancel
                    </ButtonText>
                  </Grid>
                  {activeStep === Steps.Details ?
                    <></> :
                    <Grid item lg={5}>
                      <ButtonText
                        isFullWidth
                        onClick={handleBack}
                        aria-label={"Back to previous step"}
                      >
                        Back
                      </ButtonText>
                    </Grid>

                  }
                </Grid>
                <Grid item lg={2}>
                  <ButtonText
                    isFullWidth
                    onClick={isFinalStep() ? handleSubmit : handleValidateStep}
                  >
                    {isFinalStep() ? 'Save & Preview' : 'Continue'}
                  </ButtonText>
                </Grid>
            </Grid>
            </Grid>
        </Grid>
      </Grid>
	 {dialogOpen ?
                <OneButtonDialog title={'Thank you for your submission'} btnText={'Go To Products Page'} btnNavigate={btnNavigateStr}
                  content={'We will review your submission and get back to you shortly via email regarding the status of your product.'} /> :
                <div />}
    </>
  );
};
